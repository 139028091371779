import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  useDisclosure,
  Button,
  Text,
  useColorModeValue,
  Heading,
} from "@chakra-ui/react";
import { COLORS } from "../../../constants/colors";
import { BsFillCreditCardFill } from "react-icons/bs";

export const ConfirmPaymentModal = () => {
  const bgColor = useColorModeValue(COLORS.primary, COLORS.green);
  const cardBg = useColorModeValue(COLORS.lightGrey, COLORS.white);
  const btnBg = useColorModeValue(COLORS.lightGrey, COLORS.green);
  const btnColor = useColorModeValue(COLORS.green, COLORS.white);
  

  const { isOpen, onOpen, onClose } = useDisclosure();
  return (
    <>
      <Button
        bg={btnBg}
        color={btnColor}
        onClick={onOpen}
        _hover={{ backgroundColor: `${btnBg}` }}
      >
        Pay Now
      </Button>
      <Modal size="sm" isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent py="50px" bg="black">
          <ModalBody>
            <Heading fontSize="20px" color={cardBg} pb="30px">
              Choose Payment Method
            </Heading>
            <Text color={COLORS.lightGrey}>
              Kindly select your preferred payment method. You'll be receiving
              an acknoweledgement via SMS
            </Text>
            <Button
              w={"100%"}
              mt="50px"
              bg={bgColor}
              color={cardBg}
              _hover={{ backgroundColor: `${bgColor}` }}
            >
              Pay with checkout
            </Button>
            <Button
              w={"100%"}
              mt="20px"
              border={`1px solid ${cardBg}`}
              color={cardBg}
            >
              Pay with bank transfer
            </Button>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};
