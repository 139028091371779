import {
  Avatar,
  Box,
  Center,
  Flex,
  Img,
  ScaleFade,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import React, { useContext, useEffect, useState } from "react";
import FullPageLoader from "../../components/FullPageLoader";
import Container from "../../components/Container";
import Header from "../../components/Header";
import { transition } from "../..";
import Footer from "../../components/footer";
import { COLORS } from "../../constants/colors";
import { BsFillPlusCircleFill } from "react-icons/bs";
import { addBtnStyles } from "../issue report";
import { useNavigate } from "react-router-dom";
import { PROTECTED_PATHS } from "../../app/constants";
// import { BiDotsVerticalRounded } from "react-icons/bi";
import { getCard, getFamily } from "./services";
import { UserProfileContext } from "../../context/userProfileContext";
import { AuthContext } from "../../context/authContext";

export const IdCard = () => {
  const appTextColor = useColorModeValue(COLORS.black, COLORS.white);
  const { userProfile } = useContext(UserProfileContext);
  const { userInfo } = useContext(AuthContext);
  const [family, setFamily] = useState([]);
  const [card, setCard] = useState([]);
  const [loading, setLoading] = useState(false);
  const cardBg = useColorModeValue(COLORS.lightGrey, COLORS.white);
  const bgColor = useColorModeValue(COLORS.primary, COLORS.green);
  const { REQUEST_ID } = PROTECTED_PATHS;
  const { userId } = userInfo;
  const navigate = useNavigate();

  useEffect(() => {
    getFamily(userId, setLoading, setFamily);
    getCard(userId, setLoading, setCard);
  }, [userId]);

  console.log("card", card);

  return loading ? (
    <FullPageLoader />
  ) : (
    <ScaleFade initialScale={0.3} in={true} {...transition}>
      <Box>
        <Box shadow="sm">
          <Container>
            <Header menu />
          </Container>
        </Box>
        <Flex pt="20px">
          <Container>
            {card.length > 0 ? (
              <>
                {card.map((datum, i) => (
                  <Box
                    key={i}
                    bg={COLORS.chartbg}
                    p="15px"
                    rounded={"lg"}
                    mb="10px"
                  >
                    <Flex justify={"space-between"} w={"100%"} align={"center"}>
                      <Avatar
                        src={datum?.passport}
                        name={datum?.name}
                        alt="profile"
                      />
                      <Text color={cardBg}>{datum?.name || "N/A"}</Text>
                      <Text color={bgColor}>
                        {datum?.cardHolder?.status || "N/A"}
                      </Text>
                    </Flex>

                    {/* <Menu>
                        {({ isOpen }) => (
                          <>
                            <MenuButton
                              isActive={isOpen}
                              // as={Button}
                              rightIcon={
                                <BiDotsVerticalRounded
                                  color={cardBg}
                                  size={"1.5rem"}
                                  mt={"10px"}
                                />
                              }
                            >
                              {isOpen ? (
                                <BiDotsVerticalRounded
                                  color={cardBg}
                                  size={"1.5rem"}
                                  mt={"10px"}
                                />
                              ) : (
                                <BiDotsVerticalRounded
                                  color={cardBg}
                                  size={"1.5rem"}
                                  mt={"10px"}
                                />
                              )}
                            </MenuButton>
                           <MenuList color={"white"}>
                              <MenuItem>Ban</MenuItem>
                              </MenuList> 
                          </>
                        )}
                      </Menu>*/}
                  </Box>
                ))}
              </>
            ) : (
              <Center flexDirection="column" h="70vh" w="100%">
                <Img src="/empty.svg" alt="empty" />
                <Text
                  py="5"
                  fontWeight="500"
                  textAlign="center"
                  color={appTextColor}
                >
                  You do not have any active card yet
                </Text>
              </Center>
            )}

            <Flex {...addBtnStyles}>
              <BsFillPlusCircleFill
                color={bgColor}
                size={"2.5rem"}
                onClick={() => navigate(REQUEST_ID)}
              />
            </Flex>
          </Container>
        </Flex>
        <Footer />
      </Box>
    </ScaleFade>
  );
};

