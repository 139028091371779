import { errorNotifier, successNotifier } from '../../../components/NotificationHandler';
import http, { AUTH_ROUTES } from '../../../services/api';

export const inviteGuest = async (payload, setLoading, setGuestCode, onOpen, onClose, navigate) => {
  try {
    setLoading(true);
    const {
      data: { result },
    } = await http.post(AUTH_ROUTES.INVITE_GUEST, payload);
    setGuestCode(result.data.guestCode);

    successNotifier(result?.message);

    setLoading(false);
    result.data.guestCode && onOpen();

    // onClose();
  } catch (error) {
    onClose();
    setLoading(false);

    if (!error.response) {
      errorNotifier('Please Check your internet');
      return;
    }
    error.response.data.message ? errorNotifier(error.response.data.message) : errorNotifier(error.response.data.result.message);
  }
};
