export const AUTH_ROUTES = {
  LOGIN: `/user/login`,
  UPDATE_TENANT_CODE: `/user/update-code`,
  INVITE_GUEST: `/user/invite-guest`,
  RESET_PASSWORD: "/user/reset-password",
  VALIDATE_OTP: "/user/validate-otp",

  GET_GUESTS: ({ userId, sort = "desc", limit = 40, skip = 0 }) =>
    `/user/guests/${userId}?sort=${sort}&limit=${limit}&skip=${skip}`,

  GET_CONTACT_LIST: (userId) => `/user/contact-list/${userId}?userId=${userId}`,

  DELETE_CONTACT: (userId, contactId) =>
    `/user/contact-list/${userId}/${contactId}`,

  GET_BANNER: (userId) => `/user/banner/${userId}`,
  GET_EVENT: (userId) => `/admin/event/${userId}`,

  // ID card
  GET_FAMILY: (userId) => `/user/families/${userId}`,
  GET_CARD: (userId) => `/card/?parent=${userId}&status=request`,
  POST_CARD: `/card/request`,

  GET_MANAGER_ANNOUNCEMENT: (userId) =>
    `/user/announcement/${userId}?sort=desc`,
  GET_USER_PROFILE: (userId) => `/user/profile/${userId}`,
  GET_TENANT_PROFILE_SETTING: (userId) => `/user/profile-settings/${userId}`,
  POST_TENANT_PROFILE_SETTING: "/user/profile-settings",
  EDIT_TENANT_PROFILE: (userId) => `/user/profile/${userId}`,

  DELETE_GUEST: (userId, guestId) => `/user/${userId}/guests/${guestId}`,
  // emergency
  GET_EMERGENCY: (userId) => `/user/emergencies/${userId}`,
  CREATE_EMERGENCY: "/user/create-emergency",

  // notifications
  GET_NOTIFICATIONS: (userId) =>
    `/user/notifications/${userId}?sort=desc&limit=20&skip=0`,

  // estate due
  // VERIFY_PAYMENT: `/user/verify-estate-due-payment`,
  GET_ESTATE_DUE: (userId) =>
    `/user/estate-dues/${userId}?skip=0&sort=desc&userId=${userId}`,
  GET_ESTATE_DUES: (userId) => `/manager/estate-dues/${userId}?sort=desc`,
  GET_ESTATE_INFO: (userId) => `/manager/estate-information/${userId}`,
  // PAY_DUE: `/user/create-estate-due`,
  // SEND_USER_PAYMENT_DATA: '/user/initialize-estate-due-payment',
  INITIALIZE_ESTATE_DUE_PAYMENT: "/user/initialize-estate-due-payment",
  VERIFY_PAYMENT: `/user/verify-estate-due-payment`,
  GET_ALL_PREVIOUS_TRANSACTIONS: (userId) =>
    `wallet/transactions/${userId}?userId=${userId}&sort=desc`,

  // issue report
  REPORT_ISSUE: `/user/report-issue`,
  GET_ISSUE_REPORTS: (userId) => `/user/issues/${userId}?sort=asc`,
  DELETE_ISSUE: (userId, issueId) => `/user/issues/${issueId}?userId=${userId}`,
  GET_SINGLE_REPORT_DETAILS: (userId, reportId) =>
    `/user/issues/${userId}?sort=asc&skip=0&id=${reportId}`,

  // Bill Analysis
  GET_OUTSTANDING_BILLS: (userId) => `/user/outstanding-bill?userId=${userId}`,
  GET_BILL_ANALYSIS: (userId, dueType) =>
    `/user/bill-analysis?dueType=${dueType}&userId=${userId}`,
  // GET_BILL_ANALYSIS: (userId) =>
  //   `/user/total-bill-analysis?dueType=service-charge&userId=${userId}`,
  GET_TOTAL_BILL: (userId) => `/user/total-bill-analysis?userId=${userId}`,

  // chat and message
  GET_CHAT_MESSAGE: (issueId, userId) =>
    `/user/issue/chat/${issueId}?userId=${userId}&limit=10000000000`,
  SEND_CHAT_MESSAGE: "/user/issue/chat",

  // errnads
  GET_ERRANDS: ({ userId, limit = 20 }) =>
    `/user/errands/${userId}?sort=asc&limit=${limit}`,
  GET_ERRANDS_PACKAGES: (userId) =>
    `/user/errand-packages/${userId}?sort=asc&limit=20&skip=0`,
  SUBSCRIBE_TO_ERRAND: "/user/errand-subscripion",

  // offer
  GET_OFFERS: (userId) =>
    `/user/offers/${userId}?sort=asc&limit=20&skip&status=Active`,
  GET_SINGLE_OFFER_DETAILS: (userId, offerId) =>
    `/user/offers/${userId}?sort=asc&limit=20&id=${offerId}`,
  //   join offer
  JOIN_OFFER: (userId, offerId) =>
    `/user/offers/${userId}/${offerId}/join-offer`,

  // advert
  POST_ADVERT: "/user/post-adverts",
  GET_ADVERT_PRODUCTS: ({ userId, limit }) =>
    `/user/adverts/${userId}?sort=desc&limit=${limit}&status=APPROVED`,
  GET_ADVERT_PRODUCTS_BY_CATEGORY: ({ userId, category }) =>
    `/user/adverts/${userId}?sort=desc&category=${category}&status=APPROVED`,
  GET_CATEGORY_LIST: ({ userId, category }) =>
    `/user/adverts/${userId}?sort=asc&category=${category}`,
  GET_SINGLE_ADVERT_PRODUCT: (userId, productId) =>
    `/user/adverts/${userId}?sort=asc&skip=0&id=${productId}`,
  GET_MARKET_BANNER: (userId) => `/user/banner/${userId}`,
  GET_CATEGORIES: (userId) => `/user/advert-categories/${userId}`,
  SEARCH_AND_FILTER_ADVERT_PRODUCTS: ({
    userId,
    category,
    searchKey,
    searchValue,
  }) =>
    `/user/search-adverts/${userId}?category=${category}&searchKey=${searchKey}&searchValue=${searchValue}`,

  //wallet
  INITIALIZE_BUY_POINTS: "/wallet/initialize-payment",
  VERIFY_BUY_POINTS: "/wallet/verify-payment",
  GET_WALLET: (userId) => `/wallet/${userId}`,
  GET_BANK: (userId) => `/bank-account/${userId}`,
  GET_ACCOUNT: (userId) => `/user/profile/${userId}`,
  GET_ACCOUNT_STATEMENT: `/user/account-statement`,

  // GET_TRANSACTION

  // electricity
  GET_ECTRICITY_PLANS: (userId) => `/wallet/electricity-plans/${userId}`,
  PAY_ELECTRICITY_BILL: "/wallet/disco-subscription",

  //Airtime
  PURCHASE_AIRTIME: "/wallet/purchase-airtime",

  //data
  // GET_DATA_PLANS: (userId) => `/wallet/data-plans/${userId}?isp=MTN`,
  GET_DATA_PLANS: (userId) => `/user/dataplan/${userId}?limit=100`,
  BUY_DATA_SUBSCRIPTION: "/wallet/data-subscription",

  //cable tv
  // GET_CABLE_PLANS: (userId) => `/wallet/cable-plans/${userId}`,
  GET_CABLE_PLANS: (userId) => `/user/cableplan/${userId}?limit=50`,
  PAY_CABLE_SUBSCRIPTION: "/wallet/cable-subscription",

  //Special request
  PAY_SPECIAL_REQUEST: "/user/special-request",
  VERIFY_SPECIAL_REQUEST: "/user/special-request/verify",
  GET_CONTRACTOR_LIST: (userId) => `/user/contractor-lists/?userId=${userId}`,
  GET_VEHICLE_LIST: (userId) => `/user/vechicle-lists/?userId=${userId}`,
  GET_PARTY_EVENTS: (userId) =>
    `/user/special-request/${userId}?type=event&sort=desc`,
  GET_SINGLE_PARTY_EVENT: (userId, eventId) =>
    `/user/special-request/${userId}?type=event&id=${eventId}`,
  GET_VENUE: (userId) => `/user/venue/${userId}`,
  DELETE_GUEST_FROM_EVENT_LIST: (userId, eventId) =>
    `/user/special-request/${userId}/delete-guest-list/${eventId}`,
  DELETE_SPECIAL_REQUEST: (userId, requestId) =>
    `/user/special-request/${userId}/delete-request/${requestId}`,
  GET_SPECIAL_REQUEST_PRICES: (userId) => `/user/estate-information/${userId}`,

  // GET_SINGLE_APPROVED_PARTY:
  EDIT_PARTY_REQUEST: (userId, requestId) =>
    `/user/special-request/${userId}/${requestId}`,
  ADD_MORE_GUEST: (userId, eventId) =>
    `/user/special-request/${userId}/guest-list/add-more/${eventId}`,

  //shop
  GET_USER_ACTIVE_PRODUCTS: (userId) =>
    `/user/adverts/${userId}?sort=asc&skip=0&userId=${userId}`,
  GET_SINGLE_USER_ACTIVE_PRODUCT: (userId, productId) =>
    `/user/adverts/${userId}?sort=asc&skip=0&userId=${userId}&id=${productId}`,
  ARCHIEVE_PRODUCT: (productId) => `/user/archive-advert/${productId}`,

  GET_USER_ARCHIEVED_PRODUCTS: (userId) =>
    `/user/archived-adverts/${userId}?status=ARCHIVED`,
  GET_SINGLE_ARCHIEVED_PRODUCT: (userId, productId) =>
    `/user/archived-adverts/${userId}?status=ARCHIVED&id=${productId}`,

  DELETE_USER_PRODUCT: (userId, productId) =>
    `/user/advert/${userId}/${productId}`,

  RESELL_PRODUCT: "/user/resell",

  // VOTE: '/user/vote/polls',
  GET_POLLS: (userId) => `/user/polls?userId=${userId}`,
  GET_SINGLE_POLL: (userId, pollId) =>
    `/user/polls?userId=${userId}&id=${pollId}`,
  VOTE_AN_OPTION: "/user/polls/vote",
};
