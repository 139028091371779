import React from "react";
import { Bar } from "react-chartjs-2";
import Chart from "chart.js/auto";


const BillsBarChart = ({ data }) => {
  console.log(data, "datta")
  const chartData = {
    labels: data.map((item) => `${item.monthName} ${item.year}`),
    datasets: [
      {
        label: "Total Amount Paid",
        data: data.map((item) => item.totalAmountPaid),
        backgroundColor: "rgba(54, 162, 235, 0.2)",
        borderColor: "rgba(54, 162, 235, 1)",
        borderWidth: 1,
        barPercentage: 0.5,
      },
    ],
  };

  const options = {
    scales: {
      x: {
        type: "category",
        labels: data.map((item) => `${item.monthName} ${item.year}`),
      },
      y: {
        beginAtZero: true,
        suggestedMin: 0,
        ticks: {
          stepSize: 1000,
        },
      },
    },
    plugins: {
      legend: {
        display: false,
      },
    },
  };

  return (
    <div style={{ height: "100%", width: "100%" }}>
      <Bar data={chartData} options={options} />
    </div>
  );
};

export default BillsBarChart;

// import React from "react";
// import { Bar } from "react-chartjs-2";
// import Chart from "chart.js/auto";

// const BillsBarChart = ({ data }) => {
//   console.log(data, "data")
//   const chartData = {
//     labels: data.map((item) => item.dueType),
//     datasets: [
//       {
//         label: "Total Amount Paid",
//         data: data.map((item) => item.totalAmountPaid),
//         backgroundColor: ["rgba(54, 162, 235, 0.2)"],
//         borderColor: ["rgba(54, 162, 235, 1)"],
//         borderWidth: 1,
//         barPercentage: 0.5,
//       },
//     ],
//   };

//   const options = {
//     scales: {
//       x: {
//         type: "category", // Use 'category' for the x-axis
//         labels: data.map((item) => item.dueType),
//       },
//       y: {
//         beginAtZero: true,
//         suggestedMin: 0,
//         // suggestedMax:
//         //   Math.max(...data.map((item) => item.totalAmountPaid)) + 1000,
//         ticks: {
//           stepSize: 1000,
//         },
//       },
//     },
//     plugins: {
//       legend: {
//         display: false,
//       },
//     },
//   };

//   return (
//     <div style={{ height: "100%", width: "100%" }}>
//       <Bar data={chartData} options={options} />
//     </div>
//   );
// };

// export default BillsBarChart;
