import http, { AUTH_ROUTES } from "../../../services/api";

export const getOutstandingBill = async (
  userId,
  setIsLoading,
  setOutstandingBills
) => {
  setIsLoading(true);
  try {
    const {
      data: {
        result: { data },
      },
    } = await http.get(AUTH_ROUTES.GET_OUTSTANDING_BILLS(userId));
    console.log("OutstandingBills", data);
    setOutstandingBills(data);
    setIsLoading(false);
  } catch (error) {
    setIsLoading(false);
  }
};

export const getTotalBill = async (userId, setIsLoading, setTotalBill) => {
  setIsLoading(true);
  try {
    const {
      data: {
        result: { data },
      },
    } = await http.get(AUTH_ROUTES.GET_TOTAL_BILL(userId));
    console.log("TotalBill", data);
    setTotalBill(data);
    setIsLoading(false);
  } catch (error) {
    setIsLoading(false);
  }
};

export const getBillAnalysis = async (userId, dueType, setIsLoading, setBillAnalysis) => {
  setIsLoading(true);
  try {
    const {
      data: {
        result: { data },
      },
    } = await http.get(AUTH_ROUTES.GET_BILL_ANALYSIS(userId, dueType));
    console.log("BillAnalysis", data);
    setBillAnalysis(data);
    setIsLoading(false);
  } catch (error) {
    setIsLoading(false);
  }
};
