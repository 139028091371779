import http, { AUTH_ROUTES } from '../../../services/api';

export const getIssueReports = async (userId, setIssueReports, setLoading) => {
  setLoading(true);
  try {
    const {
      data: {
        result: { data },
      },
    } = await http.get(AUTH_ROUTES.GET_ISSUE_REPORTS(userId));

    setIssueReports(data?.issues);
  } catch (error) {
    console.log(error?.response);
  } finally {
    setLoading(false);
  }
};

export const getIssueReportDetails = async (userId, reportId, setReportDeatils, setLoading) => {
  setLoading(true);
  try {
    const {
      data: {
        result: { data },
      },
    } = await http.get(AUTH_ROUTES.GET_SINGLE_REPORT_DETAILS(userId, reportId));

    setReportDeatils(data?.issues[0]);
  } catch (error) {
    console.log(error.response);
  } finally {
    setLoading(false);
  }
};
