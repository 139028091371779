import "./App.css";
import { useEffect } from "react";
import AuthenticatedApp from "./app/AuthenticatedApp";
import UnAuthenticatedApp from "./app/UnAuthenticatedApp";
import { useAuth } from "./context/authContext";
import { useNavigate } from "react-router-dom";
import { baseURL } from "./services/api";
import { urlBase64ToUint8Array } from "./send-sw-details";

const WEB_PUSH_PUBLIC_KEY =
  "BL_9glFng3bdStxfOmhe7M3jT06FzBTUZsjbF8dFvBEhBslgVxay_mYJtmp8t3GevVCBzXyz-RxbkaYLSa3ZYOk";

function App() {
  const navigate = useNavigate();
  const { isLoggedin, setIsLoggedin, userInfo } = useAuth();
  const { userId } = userInfo;

  useEffect(() => {
    if (!userId) return;

    async function subscribe() {
      const sw = await navigator.serviceWorker.ready;
      const push = await sw.pushManager.subscribe({
        userVisibleOnly: true,
        applicationServerKey: urlBase64ToUint8Array(WEB_PUSH_PUBLIC_KEY),
      });

      const payload = { subscription: push, userId };

      await fetch(`${baseURL}/push-notification/notifications/subscription`, {
        method: "POST",
        body: JSON.stringify(payload),
        headers: {
          "content-type": "application/json",
        },
      });
    }

    if ("serviceWorker" in navigator) {
      window.addEventListener("load", async () => {
        try {
          const sw = await navigator.serviceWorker.register("./sw.js");
          console.log("YES SW REGISTERED", sw);
        } catch (e) {
          console.log(e, "NO SW REGISTERED");
        }
      });
    }

    subscribe();
  }, [userId]);

  useEffect(() => {
    const token = localStorage.getItem("11p221#");
    const old = localStorage.getItem("old");

    if (!token) {
      setIsLoggedin(false);

      if (old) {
        return navigate("/");
      }
      // if (old) {
      //   return navigate("/login");
      // }
    } else {
      setIsLoggedin(true);
    }
  }, [setIsLoggedin]); // eslint-disable-line

  // const token = localStorage.getItem('11p221#');

  // if (token) {
  //   const decoded = jwtDecode(token);
  //   const expiryDate = new Date(decoded.exp * 1000);
  //   console.log('expiryDate', expiryDate);
  // }

  if (isLoggedin) {
    return <AuthenticatedApp />;
  }

  return <UnAuthenticatedApp />;
}

export default App;
