import { PROTECTED_PATHS } from '../../../app/constants';
import { errorNotifier } from '../../../components/NotificationHandler';
import { timeout } from '../../../components/utils/timeout';
import http, { AUTH_ROUTES } from '../../../services/api';

export const initializePayment = async (payload, setIsLoading, setPaymentData, setPaymentModal) => {
  setIsLoading(true);
  try {
    setIsLoading(true);
    const {
      data: {
        result: { data },
      },
    } = await http.post(AUTH_ROUTES.INITIALIZE_ESTATE_DUE_PAYMENT, payload);

    setPaymentData(data);
    setPaymentModal(true);
  } catch (error) {
    errorNotifier(error?.response?.data.errors[0].message);
  } finally {
    setIsLoading(false);
  }
};

export const verifyEstateDuePayment = async (payload, navigate) => {
  const { MY_WALLET } = PROTECTED_PATHS;

  try {
    await http.post(AUTH_ROUTES.VERIFY_PAYMENT, payload);

    timeout(() => navigate(MY_WALLET), 2000);
  } catch (e) {
    errorNotifier(e.response.data.message);
  }
};

export const getEstateDue = async (userId, setIsLoading, setEstateDue) => {
  setIsLoading(true);
  try {
    const {
      data: {
        result: { data },
      },
    } = await http.get(AUTH_ROUTES.GET_ESTATE_DUE(userId));

    setEstateDue(data?.estateDues);
    setIsLoading(false);
  } catch (error) {
    setIsLoading(false);
  }
};

export const getEstateInfo = async (userId, setIsLoading, setEstateInfo) => {
  setIsLoading(true);
  try {
    const {
      data: {
        result: { data },
      },
    } = await http.get(AUTH_ROUTES.GET_ESTATE_INFO(userId));

    setEstateInfo(data);
    setIsLoading(false);
  } catch (error) {
    setIsLoading(false);
  }
};
